import { useEffect, useState } from 'react';
import toDollar from 'utils/toDollar';

const getPreTaxTotal = (selectedOrder) => {
  const [preTax, setPreTax] = useState(0);
  useEffect(() => {
    if (selectedOrder.orderNumber) {
      setPreTax(toDollar(selectedOrder.totalPrice.centAmount));
    }
  }, [selectedOrder.id]);
  return preTax;
};

const getTaxedTotal = (selectedOrder) => {
  const [taxedTotal, setTaxedTotal] = useState(0);
  useEffect(() => {
    if (selectedOrder.orderNumber) {
      setTaxedTotal(toDollar(selectedOrder.taxedPrice.totalGross.centAmount));
    }
  }, [selectedOrder.id]);
  return taxedTotal;
};

const getShipping = (selectedOrder) => {
  const [shipping, setShipping] = useState(0);
  const getShippingAmount = () => {
    if (selectedOrder.orderNumber) {
      return selectedOrder.customLineItems.reduce((prev, curr) => {
        const amount1 = prev?.money?.centAmount ? prev.money.centAmount : 0;
        const amount2 = curr?.money?.centAmount ? curr.money.centAmount : 0;
        return amount1 + amount2;
      }, 0);
    }
    return 0;
  };

  useEffect(() => {
    setShipping(toDollar(getShippingAmount()));
  }, [selectedOrder.id]);
  return shipping;
};

const getTax = (selectedOrder) => {
  const [tax, setTax] = useState(0);
  const calculateTax = (order) => {
    if (order.orderNumber) {
      setTax(
        toDollar(
          order.taxedPrice.taxPortions.reduce((prev, curr) => prev + curr.amount.centAmount, 0),
        ),
      );
    }
  };
  useEffect(() => {
    calculateTax(selectedOrder);
  }, [selectedOrder.id]);
  return tax;
};

export default { getPreTaxTotal, getTaxedTotal, getShipping, getTax };
