/* eslint-disable no-useless-catch */
import axios from 'axios';
import clover from 'utils/clover';
import storeLocation from 'utils/storeLocation';
import getAccessToken from 'utils/getAccessToken';
import { v4 as uuidv4 } from 'uuid';

interface OrderApiResult {
  orders: {
    count: number;
    limit: number;
    offset: number;
    results: Array<any>;
    total: number;
  };
}

const emptyOrderResponse: OrderApiResult = {
  orders: {
    count: 0,
    limit: 0,
    offset: 0,
    results: [],
    total: 0,
  },
};

const createCashOrder = async (cartId: string) => {
  try {
    // throw new Error('cash test')
    const uri = `${process.env.REACT_APP_CART_MS_URL}/orders/${cartId}`;
    const paymentId = uuidv4();
    const accessToken = getAccessToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const reqBody = {
      paymentMethod: 'CASH',
      id: paymentId,
    };
    const result = await axios.post(uri, reqBody, config);
    return result;
  } catch (error) {
    throw error;
  }
};

const createOrderFromCart = async (cartId: string, paymentResponse: any) => {
  try {
    // throw new Error('card test');
    const uri = `${process.env.REACT_APP_CART_MS_URL}/orders/${cartId}`;
    const paymentId = paymentResponse.data.payment.id;
    const cloverToken = clover.getCloverAccessToken().token;
    const merchantId = storeLocation.getLocationStorage().custom.fields.cloverMerchantId;
    const accessToken = getAccessToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const reqBody = {
      paymentMethod: 'CLOVER',
      id: paymentId,
      sessionId: '',
      accessToken: cloverToken,
      merchantId,
    };
    const result = await axios.post(uri, reqBody, config);
    return result;
  } catch (error) {
    throw error;
  }
};
const getOrderById = async (orderId: string) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_CART_MS_URL}/orders/${orderId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    return {};
  }
};

const getByOrderNumber = async (orderNumber: string) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/number/${orderNumber}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    return {};
  }
};

const getByEmail = async (email: string, page: number) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders?email=${email}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    return emptyOrderResponse;
  }
};

const getByStoreKey = async (storeKey: string, page: number) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/store/${storeKey}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    return emptyOrderResponse;
  }
};

const getTodaysStoreOrders = async (storeKey: string, page: number) => {
  const todaysDate = new Date().toISOString().split('T')[0];
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/store/${storeKey}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        from: todaysDate,
        page,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    return emptyOrderResponse;
  }
};

const sendEmail = async (email: string, orderId: string) => {
  try {
    const accessToken = getAccessToken();
    const reqBody = { email, orderId };
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/${orderId}/email/confirmation`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const payload = JSON.stringify(reqBody);
    const result = await axios.post(uri, payload, config);
    return result.data;
  } catch (error) {
    return error;
  }
};

const getByDate = async (from: string, to: string, page: number) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/byDate`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: { from, to, page },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    return emptyOrderResponse;
  }
};

export default {
  getOrderById,
  getByOrderNumber,
  getByEmail,
  createOrderFromCart,
  createCashOrder,
  getByStoreKey,
  getTodaysStoreOrders,
  getByDate,
  sendEmail,
};
