import { React, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { copyText } from 'language';
import clover from 'dataAccess/api/clover.ts';
import { useCart, useSetCart } from 'contexts';
import { createCart } from 'utils/cart';
import { addCartToHistory, updateProcessedCart } from 'utils/cartHistory';
import { Box } from '@mui/system';
import orders from 'dataAccess/api/orders.ts';
import shippingContext from 'utils/shippingContext';
import PaymentResponse from '../PaymentResponse/PaymentResponse';
import ClearCart from '../../../ClearCart';
import OrderModal from '../OrderModal/OrderModal';
import OrderFailureDialog from './Components/OrderFailureDialog';

const CheckoutButtons = () => {
  const [paymentResponse, setPaymentResponse] = useState({});
  const [orderResponse, setOrderResponse] = useState();
  const [open, setOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [createOrderFailure, setCreateOrderFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const cart = useCart();
  const setCart = useSetCart();
  const borderRadius = 6;
  const height = 56;
  // TODO: (Curtis) - format border radius
  const clearCart = async () => {
    const newCart = await createCart();
    setCart(newCart);
    addCartToHistory(newCart);
    shippingContext.removeShippingMethodStorage();
  };

  const getGiftCards = () => {
    const total = 0;
    let giftCards = 0;
    if (cart?.giftCards) {
      giftCards = cart.giftCards.reduce((acc, next) => {
        return acc + next.amount.centAmount;
      }, total);
    }
    const cartTotal = cart?.taxedPrice?.totalGross?.centAmount;
    if (giftCards > cartTotal) {
      return cartTotal;
    }
    return giftCards;
  };

  const getTotalPrice = () => {
    const giftCards = getGiftCards();
    const total = (cart?.taxedPrice?.totalGross?.centAmount || 0) - giftCards;
    return total;
  };

  const createOrder = async (paymentResult) => {
    try {
      const order = await orders.createOrderFromCart(cart?.id, paymentResult);
      setOrderResponse(order.data);
      setOpen(false);
      setOrderOpen(true);
      return order;
    } catch (error) {
      throw error;
    }
  };

  const handlePaymentResponse = async (result) => {
    switch (result.status) {
      case 200:
        try {
          await clover.displayThankYou();
          // create order, if success then clear cart
          const orderCreated = await createOrder(result);
          if (orderCreated) {
            await clover.printReceipt(result, orderCreated);
            updateProcessedCart(cart.id);
            await clearCart();
          } else {
            throw new Error('Order creation failed.');
          }
        } catch (error) {
          setOpen(false);
          setCreateOrderFailure(true);
          setErrorMessage(error);
        } finally {
          setTimeout(() => {
            clover.displayWelcome();
          }, 3000);
        }

        break;
      case 209: {
        setTimeout(() => {
          clover.displayWelcome();
        }, 3000);
        break;
      }

      case 400: {
        setTimeout(() => {
          clover.displayWelcome();
        }, 3000);
        break;
      }
      case 401: {
        break;
      }
      case 415: {
        break;
      }
      case 500: {
        break;
      }
      case 501: {
        break;
      }
      case 503: {
        break;
      }
      case 504: {
        break;
      }
      default: {
        // setTimeout(() => {
        //   clover.displayWelcome();
        // }, 3000);
        break;
      }
    }
  };
  const handleCashPayment = async () => {
    try {
      setLoading(false);
      setPaymentResponse({ status: 200 });
      setOpen(true);
      const order = await orders.createCashOrder(cart?.id);
      setOpen(false);
      setOrderResponse(order.data);
      setOrderOpen(true);
      await clearCart();
    } catch (error) {
      setOpen(false);
      setCreateOrderFailure(true);
      setErrorMessage(error);
    }
  };

  const handleRetry = () => {
    setOpen(true);
    if (getTotalPrice() > 0) {
      handlePaymentResponse(paymentResponse);
    } else {
      handleCashPayment();
    }
  };

  const sendPaymentRequest = async (manualEntry = false) => {
    try {
      const totalPrice = getTotalPrice();
      if (totalPrice === 0) {
        // handle 0$ transactions as cash payments
        handleCashPayment(totalPrice);
      } else {
        setOpen(true);
        setLoading(true);
        const result = await clover.paymentRequest(cart?.id, totalPrice, manualEntry);
        setLoading(false);
        // handle clover display here
        setPaymentResponse(result);
        handlePaymentResponse(result);
        setLoading(false);
      }
    } catch (error) {
      // todo: handle errors for order creation
    }
  };
  return (
    <>
      <PaymentResponse
        response={paymentResponse}
        open={open}
        loading={loading}
        resendPaymentRequest={() => sendPaymentRequest(true)}
        closeModal={() => setOpen(false)}
      />
      {orderResponse && (
        <OrderModal open={orderOpen} order={orderResponse} closeModal={() => setOrderOpen(false)} />
      )}
      <Button
        variant="contained"
        size="large"
        sx={{
          mt: 3,
          textTransform: 'none',
          width: '100%',
          borderRadius,
          height,
        }}
        onClick={() => sendPaymentRequest(false)}
        disabled={cart?.totalPrice?.centAmount < 1 || !cart?.shippingAddress}
      >
        <Typography variant="h3" component="span" color="white">
          {copyText.Cart.CheckoutButtons.proceed}
        </Typography>
      </Button>
      <Box sx={{ mt: 1 }} display="flex" justifyContent="center">
        <Button
          onClick={() => sendPaymentRequest(true)}
          disabled={cart?.totalPrice?.centAmount < 1 || !cart?.shippingAddress}
        >
          {copyText.Cart.CheckoutButtons.affirm}
        </Button>
      </Box>
      <ClearCart />
      <OrderFailureDialog
        errorMessage={errorMessage}
        open={createOrderFailure}
        onClose={() => setCreateOrderFailure(false)}
        onRetry={handleRetry}
      />
    </>
  );
};

export default CheckoutButtons;
