import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Grid,
  Snackbar,
} from '@mui/material';
import { copyText } from 'language';
import EmailIcon from '@mui/icons-material/Email';
import { useSelectedOrder } from 'contexts/orders';
import sendConfirmationEmail from 'utils/OrderConfirmationEmail';
import { Check, CopyAll } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import TextLoader from 'components/TextLoader';

const EmailModal = () => {
  const selectedOrder = useSelectedOrder();
  const [displayEmailModal, setDisplayEmailModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailStatus, setEmailStatus] = useState('');
  const [snackbar, setSnackbar] = useState(false);
  const closeModal = () => {
    setDisplayEmailModal(false);
    setCopied(false);
  };
  const openModal = () => {
    setDisplayEmailModal(true);
  };
  const emailRequest = async () => {
    setLoading(true);
    const email = selectedOrder.customerEmail;
    const orderNumber = selectedOrder.orderNumber;
    try {
      const message = await sendConfirmationEmail(email, orderNumber);
      setEmailStatus(message);
    } catch {
      setEmailStatus({ message: copyText.Orders.EmailModal.sendError });
    }
    setSnackbar(true);
    setLoading(false);
    closeModal();
  };
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(selectedOrder.customerEmail);
    setCopied(true);
  };
  const handleSnackbarClose = () => {
    setSnackbar(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <>
      <Button
        startIcon={<EmailIcon />}
        onClick={openModal}
        variant="contained"
        color="primary"
        sx={{
          boxShadow: '0px 1px 3px ',
        }}
      >
        {copyText.Orders.OrderDetails.contact.email}
      </Button>
      <Dialog
        fullWidth
        open={displayEmailModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{copyText.Orders.EmailModal.title}</DialogTitle>
        <DialogContent>
          <Typography>{copyText.Orders.EmailModal.sendTo} </Typography>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="dialog_content_heading" component="h3">
                {selectedOrder.customerEmail}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={copyToClipBoard} sx={{ p: 1 }}>
                {copied ? <Check fontSize="large" /> : <CopyAll fontSize="large" />}
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mr: 2 }}>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            {copyText.App.cancel}
          </Button>
          <Button variant="contained" color="primary" onClick={emailRequest} disabled={loading}>
            <TextLoader text={copyText.Orders.EmailModal.send} loading={loading} size={20} />
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={emailStatus}
        action={action}
      />
    </>
  );
};

export default EmailModal;
