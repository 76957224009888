import { createTheme } from '@mui/material/styles';
import { colors } from 'utils/themeColors';

const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: colors.primaryPurple,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.mediumPurple,
    },
    tertiary: {
      main: colors.darkAqua,
      contrastText: colors.white,
    },
    text: {
      primary: colors.lightMidnight,
      secondary: colors.mediumPurple,
    },
    divider: colors.grayUtility,
    error: {
      main: colors.redUtility,
    },
    success: {
      main: colors.greenUtility,
    },
    label_promo: {
      main: colors.darkAqua,
      contrastText: colors.white,
    },
    label_bestseller: {
      main: colors.lightPurpleBG,
      contrastText: colors.primaryPurple,
    },
    ...colors,
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: colors.primaryPurple,
    },
    h2: {
      fontWeight: 700,
      color: colors.primaryPurple,
    },
    h3: {
      fontWeight: 700,
      fontSize: 12,
      color: colors.primaryPurple,
    },
    h4: {
      fontWeight: 700,
      color: colors.primaryPurple,
    },
    h5: {
      fontWeight: 700,
      color: colors.primaryPurple,
    },
    h6: {
      fontWeight: 700,
      color: colors.primaryPurple,
    },
    sub_header: {
      fontWeight: 700,
      color: colors.primaryPurple,
      fontSize: 20,
    },
    sub_title: {
      fontWeight: 700,
      color: colors.primaryPurple,
      fontSize: 13,
    },
    dialog_content_heading: {
      fontWeight: 700,
      fontSize: 30,
      color: colors.primaryPurple,
    },
    fontWeightRegular: 400,
    fontSize: 14,
  },
  spacing: 8,
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            fontWeight: 700,
            fontSize: 12,
            color: colors.primaryPurple,
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            fontWeight: 700,
            fontSize: 12,
            color: colors.white,
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
