import React from 'react';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useOktaClaims } from 'contexts';
import { copyText } from 'language';
import ReturnModal from './ReturnModal';
import EmailModal from './EmailModal';

const OrderActions = () => {
  const oktaClaims = useOktaClaims();
  const { orderId } = useParams();
  return (
    <Grid container sx={{ mb: 1 }}>
      <Grid item sm={12} sx={{ mb: 1 }}>
        <Typography variant="subHeader">{copyText.Orders.Actions.availableActions}</Typography>
      </Grid>
      <Grid item>
        <EmailModal />
      </Grid>
      <Grid item sm={0.25} display="flex" justifyContent="center">
        <Divider orientation="vertical" />
      </Grid>
      <Grid item sm={1.5} display="flex" justifyContent="center">
        <Button variant="outlined" color="primary">
          {copyText.Orders.Actions.exchange}
        </Button>
      </Grid>
      <Grid item sm={1.5} display="flex" justifyContent="center">
        <ReturnModal />
      </Grid>
      <Grid item sm={1.5} display="flex" justifyContent="center">
        {oktaClaims?.mpos_manager && (
          <Button variant="outlined" component={RouterLink} to={`/order/${orderId}/refund`}>
            {copyText.Orders.RefundModal.refund}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default OrderActions;
