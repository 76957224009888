import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { copyText } from 'language';
import convertCentToDollar from 'utils/convertCentToDollar';
import { arrayOf, objectOf, shape, string } from 'prop-types';

const RefundDisplay = ({ orderPayments }) => {
  const [totalRefundAmount, setTotalRefundAmount] = useState(0);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const getTransactions = (payments) => {
    const transactionArray = [];
    payments?.forEach((payment) => {
      transactionArray.push(...payment.transactions);
    });
    return transactionArray;
  };
  const findRefunds = (transactions) => {
    const successfulRefunds = transactions?.filter((transaction) => {
      return transaction.type === 'Refund' && transaction.state === 'Success';
    });
    return successfulRefunds;
  };
  const findPayments = (transactions) => {
    const successfulRefunds = transactions?.filter((transaction) => {
      return transaction.type === 'Charge' && transaction.state === 'Success';
    });
    return successfulRefunds;
  };
  const calculateRefundAmount = (payments) => {
    const transactions = getTransactions(payments);
    const refunds = findRefunds(transactions);
    const refundAmount = refunds?.reduce((acc, refund) => {
      return acc + refund.amount.centAmount;
    }, 0);
    setTotalRefundAmount(convertCentToDollar(refundAmount));
  };
  const calculateAvailableFunds = (payments) => {
    const transactions = getTransactions(payments);
    const successfulPayments = findPayments(transactions);
    const availableAmount = successfulPayments?.reduce((acc, payment) => {
      return acc + payment.amount.centAmount;
    }, 0);
    setTotalAvailable(convertCentToDollar(availableAmount));
  };
  useEffect(() => {
    calculateRefundAmount(orderPayments);
    calculateAvailableFunds(orderPayments);
  }, [orderPayments]);

  return (
    <Box key="refund_display" sx={{ backgroundColor: 'lightGrayBG', mt: 1, p: 2 }}>
      <Typography variant="sub_title">{copyText.Orders.RefundInfo.refunds}</Typography>
      <Typography>{`${copyText.Orders.RefundInfo.refunded}: ${totalRefundAmount}`}</Typography>
      <Typography>{`${copyText.Orders.RefundInfo.available}: ${totalAvailable}`}</Typography>
    </Box>
  );
};

export default RefundDisplay;

RefundDisplay.propTypes = {
  orderPayments: arrayOf(
    shape({
      transactions: arrayOf(objectOf(string)),
    }),
  ),
};

RefundDisplay.defaultProps = {
  orderPayments: [],
};
