/* eslint-disable prettier/prettier */
import React from 'react';
import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { copyText } from 'language';
import { string } from 'prop-types';

const CustomerDetails = (props) => {
  const { orderNumber, orderDate, salesAgent, paymentMethod } = props;

  return (
    <Grid container key="OrderData" direction="column">
      <Grid item sm={6}>
        <Typography variant="subHeader" component="h1">
          {copyText.Orders.OrderData.orderDetails}
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <List sx={{ pt:0, pb:0 }}>
          <ListItem sx={{ pt:0, pb:0 }} disableGutters>
            <Grid container>
              <Grid item md={7} sm={5}>
                <Typography>{`${copyText.Orders.OrderData.orderNumber}: `}</Typography>
              </Grid>
              <Grid item md={3} sm={2} display="flex" sx={{ ml:3, justifyContent:'end' }}>
                <Typography sx={{ fontWeight: 'bold' }}>{`${orderNumber}`}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ pt:0, pb:0 }} disableGutters>
            <Grid container>
              <Grid item md={6} sm={5}>
                <Typography>{`${copyText.Orders.OrderData.orderDate}: `}</Typography>
              </Grid>
              <Grid item md={4} sm={2} display="flex" sx={{ ml:3, justifyContent:'end' }}>
                <Typography sx={{ fontWeight: 'bold' }}>{orderDate}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem sx={{ pt:0, pb:0 }} disableGutters>
            <ListItemText
              primary={<Typography>{copyText.Orders.OrderData.salesRep}</Typography>}
              secondary={(
                <Typography sx={{ fontWeight: 'bold' }}>
                  {salesAgent}
                </Typography>
              )}
            />
          </ListItem>
          <ListItem sx={{ pt:0, pb:0 }} disableGutters>
            <Grid container direction="column">
              <Grid item>
                <Typography>{`${copyText.Orders.OrderData.paymentMethod}`}</Typography>
              </Grid>
              <Grid item>
                {paymentMethod}
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default CustomerDetails;

CustomerDetails.propTypes = {
  orderNumber: string,
  orderDate: string,
  salesAgent: string,
  paymentMethod: string,
};

CustomerDetails.defaultProps = {
  orderNumber: copyText.Orders.CustomerDetails.notAvailable,
  orderDate: copyText.Orders.CustomerDetails.notAvailable,
  salesAgent: copyText.Orders.CustomerDetails.notAvailable,
  paymentMethod: copyText.Orders.CustomerDetails.notAvailable,
};
