const getDiscountCodes = (cart) => {
  const discountCodesArray = [];
  cart?.lineItems?.forEach((lineItem) => {
    const discounts = JSON.parse(lineItem.custom?.fields?.discounts_json);
    // only keep discount code from discounts
    discounts.forEach((item) => {
      if (discountCodesArray.length === 0) {
        discountCodesArray.push(item);
      }
      let repeat = 0;
      discountCodesArray.forEach((ele) => {
        if (ele.display_name === item.display_name) {
          repeat++;
        }
      });
      if (repeat === 0) {
        discountCodesArray.push(item);
      }
    });
    return null;
  });
  return discountCodesArray;
};

export default getDiscountCodes;
