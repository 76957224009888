import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
} from '@mui/material';
import { Container } from '@mui/system';
import copyText from 'language/enUS';
import { useSelectedOrder } from 'contexts/orders';
import React, { useEffect, useState } from 'react';
import { lang } from 'language';
import convertCentToDollar from 'utils/convertCentToDollar';
import toDollar from 'utils/toDollar';
import { Warning } from '@mui/icons-material';
import refunds from 'dataAccess/api/refunds/refunds.ts';
import TextLoader from 'components/TextLoader';
import { useParams } from 'react-router-dom';
import orders from 'dataAccess/api/orders.ts';

const Refund = () => {
  const urlParams = useParams();
  const [currentOrder, setCurrentOrder] = useState({});
  const [totalRefundAmount, setTotalRefundAmount] = useState(0);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [lineItemDetails, setLineItemDetails] = useState([]);
  const [showError, setShowError] = useState(false);

  const removeFromLineItemDetails = (id) => {
    const copy = lineItemDetails;
    const index = copy.findIndex((lineItem) => lineItem.lineItemId === id);
    copy.splice(index, 1);
    setLineItemDetails(copy);
    return copy;
  };
  const getOrderById = async () => {
    const orderId = urlParams.orderId;
    const order = await orders.getOrderById(orderId);
    setCurrentOrder(order);
  };

  const checkLimits = (copy) => {
    let withinLimits = true;
    copy.forEach((item) => {
      const maxAmount = currentOrder.lineItems.find((lineItem) => lineItem.id === item.lineItemId)
        .taxedPrice.totalGross.centAmount;
      const dollarAmount = toDollar(maxAmount);
      if (item.amount < 0 || item.amount > dollarAmount) {
        withinLimits = false;
      }
    });
    return withinLimits;
  };

  const updateTotalRefund = (copy) => {
    setShowError(false);
    const withinLimits = checkLimits(copy);
    if (!withinLimits) {
      setShowError(true);
    }

    let sum = 0;

    copy.forEach((item) => {
      sum += item.amount;
    });
    setTotalRefundAmount(sum.toFixed(2));
  };

  const addToTotalRefund = (item, amount) => {
    const copy = lineItemDetails;
    const index = copy.findIndex((refundItem) => refundItem.lineItemId === item.id);
    if (index !== -1) {
      copy[index].amount = amount;
    } else {
      copy.push({
        lineItemId: item.id,
        sku: item.variant.sku,
        amount,
        itemQuantity: item.quantity,
      });
    }
    setLineItemDetails(copy);
    return copy;
  };

  const handleInput = async (item, e) => {
    let copy;
    if (e.target.value === '') {
      copy = removeFromLineItemDetails(item.id);
    } else {
      const amount = Number(e.target.value);
      copy = addToTotalRefund(item, amount);
    }
    updateTotalRefund(copy);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const params = {
        orderId: currentOrder.id,
        refundAmount: Number(totalRefundAmount),
        lineItemDetails,
        reason,
      };
      await refunds.automaticRefund(params);
      // handle result
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const orderColumns = {
    idColumn: copyText.Orders.Refunds.productName,
    columns: [
      copyText.Orders.Refunds.sku,
      copyText.Orders.Refunds.price,
      copyText.Orders.Refunds.quantity,
      copyText.Orders.Refunds.amountRefunded,
      copyText.Orders.Refunds.amountAvailable,
      copyText.Orders.Refunds.amountToRefund,
    ],
  };

  useEffect(() => {
    getOrderById();
  }, []);

  return (
    <Container disableGutters sx={{ mb: 15, p: 3 }}>
      <Grid container justifyContent="flex-start" alignItems="center" sx={{ p: 1.5, mt: 10 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">{copyText.Orders.Refunds.partialRefund}</Typography>
          <Typography variant="h6" color="secondary">
            {copyText.App.orderNumber}: {currentOrder?.orderNumber}
          </Typography>
          <Typography variant="h6" color="secondary">
            {copyText.App.email}: {currentOrder?.customerEmail}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Alert icon={<Warning />} color="warning">
            {copyText.Orders.Refunds.warning}
          </Alert>
          <TableContainer
            component={Paper}
            sx={{ height: { xs: '72vh', sm: '50vh', md: '42vh' }, pl: 1, pr: 1 }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ borderBottom: 3 }}>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {orderColumns.idColumn}
                  </TableCell>
                  {orderColumns.columns.map((col) => (
                    <TableCell key={col} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {currentOrder?.lineItems?.length < 1 && (
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ fontWeight: 'bold' }}>
                      {copyText.Layouts.TableLayout.noDataFound}
                    </TableCell>
                  </TableRow>
                )}
                {currentOrder?.lineItems?.map((row) => (
                  <TableRow key={row.id} value={row} sx={{ borderBottom: 2 }}>
                    <TableCell sx={{ textAlign: 'center' }}>{row.name[lang]}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.variant.sku}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {convertCentToDollar(row.taxedPrice.totalGross.centAmount)}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{row.quantity}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {/* amount refunded  */}
                      {/* SUNSET: (toddf) update this to display actual amount refunded */}
                      $0.00
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      {/* amountAvailable */}
                      {/* SUNSET: (toddf) update this to display actual amount available */}

                      {convertCentToDollar(row.taxedPrice.totalGross.centAmount)}
                    </TableCell>
                    <TableCell sx={{ minWidth: 100, alignContent: 'center' }}>
                      {/* amount to refund */}
                      <TextField
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          min: 0,
                          max: row.taxedPrice.totalGross.centAmount,
                        }}
                        //   defaultValue={0}
                        type="number"
                        onChange={(e) => handleInput(row, e)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            multiline
            onChange={(e) => setReason(e.target.value)}
            minRows={4}
            placeholder={copyText.Orders.RefundModal.refundReason}
          />
        </Grid>
        <Grid
          xs={12}
          sm={6}
          item
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography textAlign="right">
            {copyText.Orders.Refunds.totalAmountToRefund} $ {totalRefundAmount}
          </Typography>
          {showError && (
            <Typography textAlign="right" color="red" variant="subtitle2">
              {copyText.Orders.Refunds.amountsError}
            </Typography>
          )}
          <Button
            disabled={lineItemDetails.length === 0 || showError || loading}
            onClick={handleSubmit}
            variant="contained"
          >
            <TextLoader
              text={copyText.Orders.Refunds.createPartialRefund}
              loading={loading}
              size={20}
            />
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Refund;
