import React, { useEffect } from 'react';
import DetailLayout from 'components/layouts/DetailLayout';
import { useHistory } from 'react-router-dom';
import { useSelectedOrder, useOrderPayment } from 'contexts/orders';
import { Grid, Typography, ImageListItem, CircularProgress } from '@mui/material';
import CustomerDetails from 'components/CustomerDetails/CustomerDetails';
import { copyText } from 'language';
import getDate from 'utils/getDate';
import VisaIcon from 'media/images/visaBlue.png';
import MastercardIcon from 'media/images/mc_symbol.svg';
import DiscoverLogo from 'media/images/discoverLogo.png';
import UseOrderSearchFunctions from '../OrderSearch/hooks/OrderSearchFunctions';
import OrderData from './OrderData';
import OrderItems from './OrderItems';
import OrderActions from './OrderActions';
import OrderTotals from '../../components/OrderTotals';
import DiscountDisplay from './DiscountDisplay';
import RefundDisplay from './RefundDisplay';

const OrderDetails = () => {
  const orderData = useSelectedOrder();
  const orderPayment = useOrderPayment();
  const shippingAddress = orderData?.shippingAddress;
  const history = useHistory();
  const customerFullName = `${shippingAddress?.firstName} ${shippingAddress?.lastName}`;
  const customerAddress = `${shippingAddress?.streetName} ${shippingAddress?.city}, ${shippingAddress?.state} ${shippingAddress?.postalCode}`;

  const { useLoadOrderByNumber } = UseOrderSearchFunctions();

  const getAgent = () => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    // This is to make sure only emails are displayed on the front end.
    if (emailRegex.test(orderData?.createdBy?.externalUserId)) {
      return orderData?.createdBy?.externalUserId;
    }
    return copyText.Orders.OrderDetails.notAvailable;
  };

  const getCardType = (brand) => {
    let card;
    if (brand === 'visa') {
      card = VisaIcon;
    } else if (brand === 'mastercard') {
      card = MastercardIcon;
    } else if (brand === 'discover') {
      card = DiscoverLogo;
    } else {
      card = 'american express';
    }
    return card;
  };

  const showPayment = () => {
    if (orderPayment) {
      return orderPayment.map((payment) => {
        if (
          payment.paymentMethodInfo.method !== 'GIFT_CARD' &&
          payment.custom?.fields?.maskedCard
        ) {
          return (
            <Grid key={payment.id} container alignItems="center">
              <Grid item>
                <ImageListItem sx={{ width: 60, mr: 1 }}>
                  <img
                    src={getCardType(payment.custom.fields?.cardBrand)}
                    alt={copyText.Orders.CustomerDetails.creditCard.alt}
                  />
                </ImageListItem>
              </Grid>
              <Grid item>
                <Typography>
                  {copyText.Orders.CustomerDetails.endingIn}
                  {payment.custom?.fields?.maskedCard}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        if (payment.paymentMethodInfo.method !== 'GIFT_CARD') {
          return (
            <Grid key={payment.id} container>
              <Grid item>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {payment.paymentMethodInfo.method.replace('_', ' ')}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        return null;
      });
    }

    return <CircularProgress />;
  };

  const lastPageFunction = () => {
    history.push('/');
  };

  const loadOrderData = () => {
    if (!orderData.orderNumber) {
      const path = history.location.pathname.split('/');
      const orderNumber = path[path.length - 1];
      useLoadOrderByNumber(orderNumber);
    }
  };

  useEffect(() => {
    loadOrderData();
  }, []);
  // console.log(orderData);
  return (
    <DetailLayout
      identifier={orderData?.orderNumber}
      lastPageFunction={lastPageFunction}
      lastPageText="Return to Order Search"
      DetailComponents={[
        OrderData({
          orderNumber: orderData?.orderNumber,
          orderDate: getDate(orderData?.createdAt),
          salesAgent: getAgent(),
          paymentMethod: showPayment(),
        }),
        CustomerDetails({
          name: customerFullName,
          phone: shippingAddress?.phone,
          email: orderData?.customerEmail,
          address: customerAddress,
        }),
      ]}
      ActionsComponent={OrderActions()}
      BreakdownComponent={OrderItems()}
      TotalsComponents={[
        OrderTotals(),
        DiscountDisplay({ order: orderData }),
        RefundDisplay({ orderPayments: orderPayment }),
      ]}
    />
  );
};

export default OrderDetails;
