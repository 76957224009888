import { createContext, useContext, useEffect, useState } from 'react';
import storeLocationUtil from 'utils/storeLocation';

const OrdersStorage = () => {
  const [orderInput, setOrderInput] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [foundOrderData, setFoundOrderData] = useState({
    count: 0,
    limit: 0,
    offset: 0,
    results: [],
    total: 0,
  });
  const [selectedOrder, setSelectedOrder] = useState({ orderNumber: '', lineItems: [] });
  const [orderPayment, setOrderPayment] = useState();
  const [selectedStore, setSelectedStore] = useState({ id: '', name: { lang: '' } });

  const getCurrentLocation = () => {
    const currentLocation = storeLocationUtil.getLocationStorage();

    setSelectedStore(currentLocation);
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);
  return {
    foundOrderData,
    setFoundOrderData,
    selectedOrder,
    orderPayment,
    setSelectedOrder,
    setOrderPayment,
    orderInput,
    setOrderInput,
    currentPage,
    setCurrentPage,
    selectedStore,
    setSelectedStore,
  };
};

const OrdersContext = createContext();

// eslint-disable-next-line react/prop-types
export const OrdersContextProvider = ({ children }) => (
  <OrdersContext.Provider value={OrdersStorage()}>{children}</OrdersContext.Provider>
);

export const useFoundOrderData = () => useContext(OrdersContext).foundOrderData;
export const useSetFoundOrderData = () => useContext(OrdersContext).setFoundOrderData;
export const useSelectedOrder = () => useContext(OrdersContext).selectedOrder;
export const useSetSelectedOrder = () => useContext(OrdersContext).setSelectedOrder;
export const useOrderPayment = () => useContext(OrdersContext).orderPayment;
export const useSetOrderPayment = () => useContext(OrdersContext).setOrderPayment;
export const useOrderInput = () => useContext(OrdersContext).orderInput;
export const useSetOrderInput = () => useContext(OrdersContext).setOrderInput;
export const useCurrentPage = () => useContext(OrdersContext).currentPage;
export const useSetCurrentPage = () => useContext(OrdersContext).setCurrentPage;
export const useSelectedStore = () => useContext(OrdersContext).selectedStore;
export const useSetSelectedStore = () => useContext(OrdersContext).setSelectedStore;
