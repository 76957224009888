import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorPage from 'views/ErrorPage/ErrorPage';
import Refund from 'views/Refund/Refund';
import { OrdersContextProvider } from 'contexts/orders';
// SUNSET: Move Orders view to new location: views/Orders.jsx
import OrderSearch from './subviews/OrderSearch/OrderSearch';
import OrderDetails from './subviews/OrderDetails/OrderDetails';
// Future Orders view location
// import Orders from './views/Orders';

const OrdersRoutes = () => {
  /**
   * --- Hierarchy ---
   * Orders Search -- "/orders"
   * |__ Individual Order -- "/orders/order/:orderId"
   * |   |__ Returns/Exchanges -- "/orders/order/:orderId/refund"
   */
  return (
    <OrdersContextProvider>
      <Router basename="/orders" window>
        <Switch>
          <Route exact path="/order/:orderId/refund" component={Refund} />
          <Route exact path="/order/:orderId" component={OrderDetails} />
          <Route exact path="/" component={OrderSearch} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </OrdersContextProvider>
  );
};

export default OrdersRoutes;
