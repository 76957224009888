const copyText = {
  App: {
    cancel: 'Cancel',
    edit: 'Edit',
    yes: 'Yes',
    send: 'Send',
    no: 'No',
    email: 'Email',
    store: 'Store',
    agent: 'Agent',
    title: 'Title',
    pageURL: 'Page URL',
    none: 'None',
    missing: 'Missing',
    close: 'Close',
    retry: 'Retry',
    print: 'Print',
    date: 'Date',
    price: 'Price',
    item: 'Item',
    items: 'Items',
    details: 'Details',
    quantityAbbreviated: 'Qty',
    add: 'Add',
    errorOccurred: 'An Error Occurred',
    transaction: 'Transaction',
    orderNumber: 'Order #',
    customerSupportPhone: '888-848-8456',
    customerSupportCenter: 'Purple Customer Support Center',
    returnWarrantyPolicy: 'For Return and Warranty policy',
    pleaseVisitUrl: 'please visit',
    returnsURL: 'www.purple.com/returns',
  },
  Layouts: {
    SearchLayout: {
      filters: 'Filters',
      search: 'Search',
    },
    TableLayout: {
      noDataFound: 'No Data Found.',
    },
  },
  Feedback: {
    sendFeedback: 'Send Feedback',
    Description: 'Description',
    send: 'Send',
    comments: 'Comments',
    featureRequest: 'Feature Request',
    bug: 'Bug',
    title: 'Feedback Title',
    other: 'Other',
    successfullySubmitted: 'Successfully submitted! Jira reference id',
    failedToSendFeedback: 'Failed to send feedback',
    tryAgain: 'Please try again or contact IT for assistance.',
  },
  Terminal: {
    noDeviceSelected: 'No Device Selected',
    connected: 'connected',
  },
  Cart: {
    CartTools: {
      CartHistory: {
        searchByEmail: 'Customer Email',
        cartDetails: 'Cart Details',
        loadThisCart: 'Load this cart',
        selectCartToSeeDetails: 'Select a cart to view its details',
        searchFailed: 'Cart history search failed due to an error',
        noCartsFound: 'No Carts Found',
      },
      DraftOrder: {
        sendDraftTitle: 'Send Draft Order',
        markingCartAsDraft: 'Marking cart as a draft...',
        sendDraft: 'Sendig draft to customer email...',
        markAsDraftFailure: 'Failed to mark cart as a draft.',
        sendDraftFailure: 'Failed to send draft order email.',
        advisoryOne:
          'This cart will be marked as a draft and details will be emailed to the customer, including the cart details, and a link for them to check out.',
        advisoryTwo:
          'The customer will not be able to edit the products or discounts in the cart. If they want to change the order, they will need to contact a sales representative.',
        addNote: 'Add a note',
        addNotePlaceholder: 'Add a personalized note (included in the email)',
      },
      missingCloverToken: 'Log in to Clover to checkout.',
      missingCloverDevice: 'Select a Clover terminal to checkout.',
      sendDraft: 'Send draft to customer email',
      dateModified: 'Date Modified',
      history: 'Carts History',
      discounts: 'Discounts',
      customerName: 'Customer Name',
      giftCards: 'Gift Cards',
      promos: 'Promo(s)',
      shipping: 'Shipping',
      recyclingFee: 'Recycling Fee',
      recyclingFeeExplanation:
        'State law requires that we charge a recycling fee when removing mattresses',

      cost: 'Free',
      tax: 'Tax',
      addShippingAddress: 'Add Shipping Address',
      updateCart: 'Update Cart',
      clearCart: 'Clear Cart',
      verifyClearCart: 'Are you sure?',
      clearCartDialog: `If you clear the cart your current order will not be saved. If possible, send a draft so
      that your customer may view these products later.`,
      updateEmail: 'Update Email',
      addEmail: 'Add Customer Email',
      cartId: 'Cart ID',
      customerEmail: 'Customer Email',
      cartPreview: 'Cart Preview',
      viewCart: 'View Cart',
      closeCartPreview: 'Close cart preview',
      editEmail: 'edit email address',
      fullName: 'Full Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      phone: 'Phone',
      phoneNumber: 'Phone Number',
      country: 'Country',
      streetAddressSteNumber: 'Street Address & Applicable Unit Number',
      streetAddressOnly: 'Street Address',
      state: 'State',
      cityStateZip: 'City, State, Zip',
      city: 'City',
      postalCode: 'Postal Code',
      customerFirstName: 'Customers First Name',
      customerLastName: 'Customers Last Name',
      add: 'ADD',
      edit: 'EDIT',
      shippingAddress: 'Shipping Details',
      removeFromCart: 'remove item from cart',
      customerInfo: 'Customer Info',
      subtractOne: 'subtract one',
      addOne: 'add one',
      email: 'Email',
      view: 'View',
      shippingMethod: 'Shipping Method',
      pickupFrom: 'Pickup From',
      removeMattress: 'Remove Mattress',
      update: 'Update',
      addressStatus: 'Address Status',
      valid: 'Valid Address',
      invalid: 'Invalid Address',
      changeStore: 'Change Store',
      pickupCarryOutOnly:
        'Available for orders where every line item shipping method is carry out or pickup.',
      useStoreAddress: 'Use Store Address',
      validAddressForWarranty: 'A valid customer address is required for warranty.',
      appliedDiscounts: 'Applied Promo Codes',
      applyGiftCard: 'Apply A Gift Card',
      giftCardNumber: 'Card Number',
      giftCardSecurityCode: 'Security Code',
      successfulGiftCard: 'Successfully applied gift card balance to cart!',
      failedGiftCard: 'Failed to apply gift card balance',
      failedAddToCart: 'Failed to add product to cart',
      failedShippingMethods: 'Failed to add default shipping method',
      applyBalance: 'Apply Balance',
      orderCreationFailed: 'Order Creation Failed',
      reason: 'Reason',
      canRetry: 'You can try to create the order again, or contact support for assistance.',
    },
    receipt: {
      soldBy: 'Sold by',
      soldTo: 'Sold to',
      totalCaps: 'TOTAL',
      pickList: 'Pick List',
      pickupAt: 'Pick up at',
      giftCardXX: 'Gift Card XX',
    },
    ItemList: {
      emptyCart: 'Your cart is empty',
    },
    CheckoutButtons: {
      amazon: 'amazon pay',
      express: 'Express Checkout',
      startOver: 'Need to start over?',
      paypal: {
        alt: 'PayPal',
      },
      proceed: 'Proceed to Checkout',
      affirm: 'Checkout with Affirm',
    },
    PaymentResponse: {
      successfulPayment: 'Payment Processed Successfully!',
      waitingForPayment: 'Waiting for payment...',
      creatingOrder: 'Creating Order...',
      paymentCancelled: 'Payment Cancelled',
      retryPayment: 'Retry Payment',
      invalidPaymentRequest:
        'Invalid Payment Request, Contact IT \n If this error persists, process the order through the website',
      invalidCloverCredentials:
        'Your Clover credentials have expired. Please log in again. \n Your progress will be saved.',
      verifyCloverStatus:
        'Verify the status of your Clover device. \n If you can connect, please retry payment. \n Otherwise, select a different device from the menu.',
      deviceInaccessible: 'Device is inaccessible, please select another device and retry payment.',
      deviceUnavailable: 'Device Unavailable',
      deviceUnavailableBody:
        'Device is unavailable, turned off, or currently handling a different request. Please choose a different device. If your device is unresponsive, alert a manager or IT for assistance.',
      paymentTimeOut: 'Payment timed out.',
      pleaseTryAgain: 'Please try again.',
      checkStatus: 'Check Status',
      viewOrder: 'View Order',
      clearDeviceOperations: 'Clear Device Operations',
      followPrompts: 'Follow the prompts on the terminal.',
      connected: 'Connected',
      disconnected: 'Disconnected',
      deviceStatus: 'Device Status',
      orderCreated: 'Order Created',
    },
    MonthlyPayments: {
      payAtOwnPace: 'Pay at Your Own Pace',
      selectAtCheckout: 'Select _____ at Checkout',
      financing: 'Monthly payments with as low as 0% APR financing',
    },
    OrderTotal: {
      total: 'Order total',
      payLater: 'pay as low as',
      duration: 'month',
    },
    PromoCode: {
      promoCode: 'Promo Code',
      hide: 'Hide',
      add: 'Add',
      enterCode: 'Enter promo code',
      apply: 'Apply',
      generateCode: 'Generate Code',
      createDiscountCode: 'Create Discount Code',
      discountType: 'Discount Type',
      discountMethod: 'Discount Method',
      discountAmount: 'Discount Amount',
      discountQuantity: 'Quantity',
      percentage: 'Percentage',
      dollar: 'Dollar',
      coupon: 'Coupon',
      discountName: 'Discount Name',
      generate: 'Generate',
      back: 'Back',
      copiedToClipboard: 'Copied To Clipboard',
      discountedProduct: 'Discounted Product',
      failedToAddPromo: 'Failed to add promo to the cart!',
      successfullyAddedToCart: 'Successfully added promo to the cart!',
      removeError: 'Something went wrong. Could not remove promo code.',
    },
  },
  CartCard: {
    edit: 'Edit',
  },
  ChipLabel: {
    bestSeller: 'BEST SELLER',
    onSale: 'ON SALE',
    specialOffer: 'Special Offer',
  },
  ErrorPage: {
    default: 'Page',
    notFound: 'Not Found.',
    somethingWrong: 'Something went wrong',
  },
  Footer: {
    orders: 'Orders',
    promotions: 'Promotions',
    shop: 'Shop',
  },
  Greeting: {
    greeting: 'Hello,',
  },
  Header: {
    logo: {
      alt: 'Purple logo',
    },
  },
  Location: {
    loading: 'Loading...',
    select: 'User Store',
    notAvailable: 'N/A',
  },
  Login: {
    greeting: 'HELLO.',
    instructions: 'Click the button below to login.',
    loginBtn: 'LOGIN WITH OKTA',
    logo: {
      alt: 'Purple logo',
    },
    redirect: 'To log in, you will be directed to',
  },
  Logout: {
    logoutBtn: 'Logout',
  },
  Orders: {
    Refunds: {
      lineItem: 'Line Item ID',
      quantity: 'Quantity',
      amountRefunded: 'Amount Refunded',
      amountAvailable: 'Amount Available',
      amountToRefund: 'Amount to Refund',
      price: 'Price',
      sku: 'Sku',
      productName: 'Product',
      warning: 'Refunds are currently in development. Some data seen here may not be accurate.',
      totalAmountToRefund: 'Total Amount to Refund:',
      partialRefund: 'Partial Refund',
      amountsError: 'One or more refund amounts are greater than the amount available to refund',
      createPartialRefund: 'Create Partial Refund',
    },
    Orders: {
      orderDetails: 'Order Details',
      orderNumber: 'Order Number:',
      searchById: 'Order Number, Email...',
      storeSelect: 'Select Store',
      storeOrders: 'Store Orders',
      orderSearch: 'Order Search',
      search: 'Search',
    },
    EmailModal: {
      title: 'Email',
      send: 'Send',
      sendTo: 'The confirmation email will be sent to: ',
      emailSent: 'Email sent.',
      sendError: 'Something went wrong: Email was not sent. Try again.',
    },
    CustomerDetails: {
      customerDetails: 'Customer Details',
      customerName: 'Customer Name',
      customerEmail: 'Customer Email',
      creditCard: {
        alt: 'Visa',
      },
      endingIn: 'Ending In ',
      paymentMethod: 'Payment Method',
      phone: 'Phone Number',
      shippingAddress: 'Shipping Address',
      method: 'Method: ',
      notAvailable: 'N/A',
    },
    Actions: {
      availableActions: 'Available Actions',
      email: 'Email',
      exchange: 'Exchange',
    },
    ItemDetails: {
      itemImg: {
        alt: 'Item image',
      },
    },
    OrderDetails: {
      contact: {
        email: 'Email',
        text: 'Text',
      },
      orderDate: 'Order Date:',
      received: 'Received:',
      salesRep: 'Sales Representative: ',
      notAvailable: 'Not Available',
      status: 'Status:',
    },
    OrderData: {
      orderBreakdown: 'Order Breakdown',
      orderDetails: 'Order Details',
      orderNumber: 'Order Number',
      orderDate: 'Order Date',
      salesRep: 'Sales Representative',
      paymentMethod: 'Payment Method',
      moreDetails: 'more details',
    },
    OrderList: {
      details: 'Details',
      noOrdersFound: 'No Orders Found',
      orders: 'Orders',
      orderNumber: 'Order Number',
      purchaseDate: 'Purchase Date',
      customerName: 'Customer Name',
      customerEmail: 'Customer Email',
      location: 'Location',
    },
    OrderTotals: {
      discounts: 'Discounts',
      giftCards: 'Gift Cards',
      orderTotal: 'Order total',
      promos: 'Promo(s)',
      shipping: 'Shipping',
      subtotal: 'Subtotal',
      taxes: 'Taxes',
      appliedDiscounts: 'Discount Codes',
    },
    Discounts: {
      usedDiscounts: 'Used Discount Codes',
      none: 'None',
    },
    RefundInfo: {
      refunds: 'Refunds',
      refunded: 'Refunded',
      available: 'Available',
      paymentTransactions: 'Payment Transactions',
      refundAmount: 'Refund Amount: ',
      transactionType: 'Transaction Type: ',
      transactionStatus: 'Transaction status: ',
    },
    ReturnBox: {
      return: 'Return',
      exchange: 'Exchange',
    },
    RefundModal: {
      payment: 'Payment',
      refund: 'Refund',
      agent: 'Agent',
      refundPreview: 'Refund Preview',
      refundAmount: 'Refund Amount',
      refundReason: 'Refund Reason',
      confirm: 'Confirm',
      paymentInfo: 'Payment Info',
      paymentType: 'Payment Type',
      lastFour: 'Last Four #',
      paymentAmount: 'Payment Amount',
      appliedRefund: 'Applied Refund Amount',
      enterAmount: 'Enter Payment Refund Amount',
      amountError: 'Please enter an amount that is less than your total amount and greater than 0.',
      dollarAmount: 'Dollar Amount',
      paymentDate: 'Payment Date',
      giftCardRefund: 'Please contact customer support for gift card refunds.',
      refundError: 'Sorry Something Went Wrong.',
      refundSuccess: 'Your Refund Was Successful.',
      tryAgain: 'Try Again',
    },
    ReturnInfo: {
      modalTitle: 'Returns',
      startRefund: 'Start Return',
      cancel: 'Cancel',
    },
  },
  ProductCard: {
    addToCart: 'Add to Cart',
  },
  Shop: {
    ShopHeader: {
      breadcrumbs: {
        root: 'Categories',
      },
      heading: 'Shop',
      variant: 'variant',
    },
    Variants: {
      error: 'Product',
    },
    Categories: {
      imgAlt: 'category image',
    },
    Products: {
      imgAlt: 'product image',
      error: 'Category',
    },
    PDP: {
      details: {
        shippingDetails: 'Shipping Details',
        shippingDimensions: 'Shipping Dimensions',
        shippingWeight: 'Weight',
      },
      variants: {
        variants: 'Variants',
        size: 'Size',
        color: 'Color',
      },
      imgAlt: 'product image',
    },
  },
  UserMenu: {
    toolTip: 'User',
  },
  Services: {
    autoRefundError: 'Auto Refund requires: cartId, refundAmount, and reason',
    refundPreviewError: 'Refund preview requires: cartId, refundAmount, and reason',
    refundFail: 'Refund failed try again',
  },
};

export default copyText;
