import { React, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Grid, Typography, CardMedia, Skeleton } from '@mui/material';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ErrorPage from 'views/ErrorPage/ErrorPage';
import { copyText, lang } from 'language';
import AddToCartBtn from 'components/AddToCartBtn/AddToCartBtn';
import { useShopProducts } from 'contexts';

import convertCentToDollar from 'utils/convertCentToDollar';
import ShopHeader from '../../components/ShopHeader/ShopHeader';
import ShippingDetails from './components/ShippingDetails';
import Variants from './components/Variants';

const PDP = () => {
  const { authState } = useOktaAuth();
  const { categoryId, productId, variantSku } = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState();
  const shopProducts = useShopProducts();

  const formatAttributes = (attributes) => {
    const resp = {};
    attributes.forEach((attr) => {
      resp[attr.name] = attr.value;
    });
    return resp;
  };

  const findVariant = (item) => {
    let resp = item.variants.find((v) => v.sku === variantSku);
    if (!resp) {
      resp = item.masterVariant;
    }
    return resp;
  };

  const findProduct = (products, prodId) => {
    return products.find((item) => item.id === prodId);
  };

  const getVariant = () => {
    if (shopProducts) {
      const products = shopProducts[categoryId];
      if (products) {
        const item = findProduct(products, productId);
        const resp = {};
        if (item) {
          const variant = findVariant(item);
          resp.attributes = formatAttributes(variant.attributes);
          resp.sku = variantSku;
          resp.name = variant.name ? variant.name[lang] : variant.attributes.title;
          resp.images = variant.images;
          resp.price = convertCentToDollar(variant.prices[0]?.value?.centAmount);
          setProduct(resp);
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getVariant();
  }, [authState, shopProducts]);

  if (!product && !loading) {
    return <ErrorPage error={copyText.Shop.Variants.error} />;
  }

  return (
    <>
      <ShopHeader />
      <Grid container spacing={4}>
        {/* Heading */}
        <Grid item xs={12}>
          <Typography component="h2" variant="h4">
            {product ? product.name : <Skeleton width="50%" />}
          </Typography>
          <Typography component="p">
            {product ? product.attributes.title : <Skeleton width="50%" />}
          </Typography>
        </Grid>

        {/* Details */}
        <Grid item xs={12} md={4}>
          {product ? (
            <CardMedia
              component="img"
              image={product?.images[0]?.url}
              alt={`${product?.images[0]?.label} ${copyText.Shop.PDP.imgAlt}`}
            />
          ) : (
            <Skeleton height={240} width={240} />
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography sx={{ fontWeight: 600, fontSize: 18 }} component="span">
            {product?.price && product?.price}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="p">
                {product ? product.attributes.subTitle : <Skeleton width="50%" />}
              </Typography>
            </Grid>

            <Variants product={product} />
            <ShippingDetails product={product} />

            <Grid item xs={12} justifyContent="flex-start">
              {product ? <AddToCartBtn details={product} /> : <Skeleton height={40} width={100} />}
            </Grid>
          </Grid>
        </Grid>

        {/* TODO: Create image list with images */}
        {/* <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              image={product.images[0].url}
              alt={product.images[0].label}
              height="150"
            />
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
};

export default PDP;
