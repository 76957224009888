import React from 'react';
import { useSelectedOrder } from 'contexts/orders';
import { Typography, List } from '@mui/material';
import { copyText } from 'language';
import CartCard from 'components/CartCard/CartCard';

const OrderItems = () => {
  const selectedOrder = useSelectedOrder();
  const { lineItems } = selectedOrder;

  return (
    <>
      <Typography variant="subHeader" component="h1">
        {copyText.Orders.OrderData.orderBreakdown}
      </Typography>
      <List sx={{ overflow: 'scroll', maxHeight: '58vh' }}>
        {lineItems.map((item) => (
          <CartCard key={item.id} productData={item} readOnly />
        ))}
      </List>
    </>
  );
};

export default OrderItems;
